import React, { useState } from "react";
import { BlobSuggestResult, aiSearchBlobs } from "../../api";
import { SearchBox } from "@fluentui/react/lib/SearchBox";

import FileIcon from "../FileIcon/FileIcon";
import { ArrowDownload16Filled, DatabaseSearch20Regular } from "@fluentui/react-icons";

import styles from "./SearchBlobList.module.css";
import Loader from "../Loader/Loader";

const SearchBlobList = () => {
    const [searchText, setSearchText] = useState<string>("");
    const [searchResults, setSearchResults] = useState<{ path: string; name: string }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchCount, setSearchCount] = useState<number>(0);

    function transformResults(results: BlobSuggestResult[]): { path: string; name: string }[] {
        return results.map(result => {
            const path = result.metadata_storage_path;
            const name = result.metadata_storage_name;
            return { path, name };
        });
    }

    const handleInputChange = async (event: any, newValue?: string) => {
        setSearchText(newValue || "");
    };

    const handleSearch = async () => {
        if (searchText.length > 0) {
            setIsLoading(true);
            setSearchCount(searchCount + 1);
            setTimeout(async () => {
                const results = await aiSearchBlobs(searchText);
                const searchResults = transformResults(results.value);
                setSearchResults(searchResults);
                if(searchResults.length > 0) {
                    setIsLoading(false);
                }
            }, 1200);
        }
    };

    return (
        <div className={styles.searchBlobWrapper}>
            <h2 className={styles.searchBlobTitle}>Search File</h2>
            <p className={styles.searchBlobDesc}>検索キーワードと、文書内コンテンツやファイル名と一致度の高いファイルを最大10件取得します。</p>
            <div className={styles.searchBoxContainer}>
                <SearchBox
                    placeholder="検索するキーワードを入力してください"
                    onSearch={handleSearch}
                    onChange={handleInputChange}
                    value={searchText}
                    className={styles.searchBox}
                />
                <button className={styles.searchButton} onClick={handleSearch}>
                    <DatabaseSearch20Regular />
                    Search
                </button>
            </div>
            {isLoading ? (
                <div className={styles.loadingContainer}>
                    <Loader />
                    <p className={styles.loadingText}>AIが検索しています。少々お待ちください。</p>
                </div>
            ) : (
                <div className={styles.listContainer}>
                    {searchResults.length > 0 &&
                        searchResults.map((result, index) => (
                            <div key={index} className={styles.contentItem}>
                                <div className={styles.download}>
                                    <p className={styles.fileText}>
                                        <FileIcon fileName={result.name} />
                                        {result.name}
                                    </p>
                                    <a href={result.path} target="_blank" download className={styles.downloadText}>
                                        Download
                                        <ArrowDownload16Filled color="black" />
                                    </a>
                                </div>
                            </div>
                        ))}
                    {searchResults.length === 0 && searchCount != 0 && <p className={styles.noResult}>検索結果はありません。</p>}
                </div>
            )}
        </div>
    );
};

export default SearchBlobList;
