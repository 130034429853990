import React from "react";
import { useState } from "react";
import { IconButton, TextField, PrimaryButton, ITextFieldStyleProps } from "@fluentui/react";

import styles from "./Feedback.module.css";

import { writeFeedback } from "../../api";
import { useMsal } from "@azure/msal-react";

type FeedbackProps = "0-good" | "1-bad_unclear" | "2-bad_inaccurate" | "3-bad_unrelated" | "4-bad_other" | "";

const Feedback = ({
    openai_uuid,
    onFeedbackClicked,
    updateFeedbackSentStatus
}: {
    openai_uuid: string;
    onFeedbackClicked: () => void;
    updateFeedbackSentStatus: (uuid: string, sent: boolean) => void;
}) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const idToken = account?.idToken;

    const [tempFeedbackComment, setTempFeedbackComment] = useState<string | undefined>("");

    const [isGoodClicked, setIsGoodClicked] = useState(false);
    const [isBadClicked, setIsBadClicked] = useState(false);
    const [isOtherFeedback, setIsOtherFeedback] = useState(false);
    const [isImprovementMessageShown, setIsImprovementMessageShown] = useState(false);

    const handleupdateFeedbackSentStatus = () => {
        onFeedbackClicked();
        updateFeedbackSentStatus(openai_uuid, true);
    };

    async function handleFeedback(feedbackValue: string, feedbackCommentValue: string) {
        const body = {
            openai_uuid: openai_uuid,
            feedback: feedbackValue,
            feedback_comment: feedbackCommentValue
        };
        await writeFeedback(body, idToken || "");
        setTimeout(() => {
            setIsGoodClicked(false);
            setIsBadClicked(false);
            handleupdateFeedbackSentStatus();
        }, 3000);
    }

    const onGoodFeedBackClicked = () => {
        setIsGoodClicked(true);
        handleFeedback("0-good", "");
    };

    const onBadFeedbackClicked = (feedbackType: FeedbackProps) => {
        setIsBadClicked(true);
        if (feedbackType != "4-bad_other") {
            setIsBadClicked(false);
            handleFeedback(feedbackType, "");
            handleImprovementMessage();
        }
    };

    const handleImprovementMessage = () => {
        setIsImprovementMessageShown(true);
        setTimeout(() => {
            setIsImprovementMessageShown(false);
        }, 3000);
    };

    return (
        <div className={styles.feedbackWrapper}>
            <div className={styles.iconButtonContainer}>
                <IconButton
                    style={{ color: "green" }}
                    iconProps={{ iconName: isGoodClicked ? "LikeSolid" : "Like" }}
                    title="Like answer"
                    ariaLabel="Like answer"
                    onClick={onGoodFeedBackClicked}
                    disabled={isBadClicked}
                    className={styles.goodIconButton}
                />
                <IconButton
                    style={{ color: "red" }}
                    iconProps={{ iconName: isBadClicked ? "DislikeSolid" : "Dislike" }}
                    title="Dislike answer"
                    ariaLabel="Dislike answer"
                    onClick={() => setIsBadClicked(true)}
                    disabled={isGoodClicked}
                    className={styles.badIconButton}
                />
            </div>
            <div className={styles.feedbackResponseContainer}>
                {isGoodClicked && <p className={styles.feedbackThankText}>フィードバックありがとうございます。</p>}
                {isBadClicked && (
                    <>
                        <div className={styles.badFeedbackButtonContainer}>
                            <p className={styles.feedbackText}>今後の改善のために、追加のフィードバックをお願いします。</p>
                            <button onClick={() => onBadFeedbackClicked("1-bad_unclear")}>①回答が不明瞭</button>
                            <button onClick={() => onBadFeedbackClicked("2-bad_inaccurate")}>②回答が不正確</button>
                            <button onClick={() => onBadFeedbackClicked("3-bad_unrelated")}>③回答が関係ない</button>
                            <button
                                onClick={() => setIsOtherFeedback(true)}
                                className={isOtherFeedback ? styles.otherFeedbackButtonActive : styles.otherFeedbackButton}
                            >
                                ④その他
                            </button>
                        </div>
                        {isOtherFeedback && (
                            <div className={styles.badFeedbackOtherInput}>
                                <textarea
                                    placeholder="回答にどのような問題がございましたか？ どのようにすれば改善できますか？"
                                    onChange={e => setTempFeedbackComment(e.target.value)}
                                    className={styles.feedbackInput}
                                />
                                <PrimaryButton
                                    text="送信"
                                    onClick={() => {
                                        handleFeedback("4-bad_other", tempFeedbackComment || "");
                                        setIsOtherFeedback(false);
                                        setIsBadClicked(false);
                                        handleImprovementMessage();
                                    }}
                                    className={styles.feedbackButton}
                                />
                            </div>
                        )}
                    </>
                )}
                {isImprovementMessageShown && <p className={styles.feedbackImproveText}>フィードバックありがとうございます。改善に努めます。</p>}
            </div>
        </div>
    );
};

export default Feedback;
