import { useState } from "react";
import { Dialog, DialogTrigger, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Button } from "@fluentui/react-components";
import styles from "./UserChatMessage.module.css";
import { motion } from "framer-motion";
import { fadeSlideIn } from "../../utils/motion";

interface Props {
    message: string;
    userName: string;
    imagePreviewUrl?: string | null;
    isUploading?: boolean;
}

export const UserChatMessage = ({ message, userName, imagePreviewUrl, isUploading }: Props) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleImageClick = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <motion.div className={styles.container} variants={fadeSlideIn("up", "tween", 0, 0.4)}>
            <div className={styles.userContainer}>
                <div className={styles.userImgContainer}>
                    <img src="../../assets/kd-logo.png" />
                </div>
                <p className={styles.userName}>{userName ? userName : "You"}</p>
            </div>
            {imagePreviewUrl && (
                <>
                    <img src={imagePreviewUrl} alt="Preview" className={styles.imagePreview} onClick={handleImageClick} />
                    <Dialog open={isDialogOpen} onOpenChange={handleDialogClose}>
                        <DialogSurface  style={{ backgroundColor: "rgba(255, 255, 255, 1)", color: "black", borderRadius: "10px" }}>
                            <DialogBody>
                                <DialogTitle  style={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>画像プレビュー</DialogTitle>
                                <DialogContent>
                                    <img src={imagePreviewUrl} alt="Preview" style={{ width: "100%" }} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleDialogClose} appearance="secondary">閉じる</Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </>
            )}
            {isUploading ? (
                <div className={styles.uploadingContainer}>
                    <div className={styles.loader}></div>
                    <p className={styles.uploadingMessage}>画像アップロード中です...</p>
                </div>
            ) : (
                <div className={styles.message}>{message}</div>
            )}
        </motion.div>
    );
};
