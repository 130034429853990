import React from "react";
import NewsCreateAdmin from "../../components/NewsCreateAdmin/NewsCreateAdmin";
import styles from "./NewsCreate.module.css";
import { useMsal } from "@azure/msal-react";

const adminGroupId = import.meta.env.VITE_REACT_APP_ADMIN_SECURITY_GROUP_ID;

const NewsCreate = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userGroups = activeAccount?.idTokenClaims?.groups || [];
    const isAuthGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(adminGroupId) : false;

    if (activeAccount && !isAuthGroupIdInGroups) {
        return (
            <div className={styles.deniedContainer}>
                <h1 className={styles.title}>News Create</h1>
                <div className={styles.loginMessageContainer}>
                    <p>このリソースを表示するのに十分な権限がありません。</p>
                </div>
            </div>
        );
    }
    return (
        <div className={styles.newsCreate}>
            <h1>News Create</h1>
            <NewsCreateAdmin />
        </div>
    );
};

export default NewsCreate;
