import React from "react";
import { useFile, useFolder } from "../../api";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from "@fluentui/react-components";
import AccordionPanelContentChirdren from "../AccordionPanelContentChildren/AccordionPanelContentChildren";
import FileIcon from "../FileIcon/FileIcon";

import styles from "./AccordionPanelContent.module.css";

import { ArrowDownload16Filled } from "@fluentui/react-icons";

const AccordionPanelContent = ({ parentFoldername, folderName }: { parentFoldername: string; folderName: string }) => {
    if (parentFoldername === "01-社内規定/") {
        const { data: files, error: filesError, isLoading: filesIsLoading } = useFile(`${parentFoldername}${folderName}`);

        if (filesError) return <div>faild to load</div>;
        if (filesIsLoading) return <div>Loading...</div>;
        return (
            <div className={styles.listContainer}>
                {files &&
                    files.map((file, index) => (
                        <div key={index} className={styles.contentItem}>
                            <div className={styles.download}>
                                <p className={styles.fileText}>
                                    <FileIcon fileName={file.name} />
                                    {file.name}
                                </p>
                                <a href={file.url} target="_blank" download className={styles.downloadText}>
                                    Download
                                    <ArrowDownload16Filled color="black" />
                                </a>
                            </div>
                        </div>
                    ))}
            </div>
        );
    } else if (parentFoldername === "02-各種文書/" || parentFoldername === "03-マネジメントシステム文書/") {
        const { data: folders, error: folderError, isLoading: folderIsLoading } = useFolder(`${parentFoldername}${folderName}/`);
        const [openItems, setOpenItems] = React.useState<number[]>([]);

        const handleToggle: AccordionToggleEventHandler<number> = async (e, data) => {
            setOpenItems(data.openItems);
        };

        if (folderError) return <div>faild to load</div>;
        if (folderIsLoading) return <div>Loading...</div>;

        return (
            <div>
                <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                    {folders &&
                        folders.map((folder, index) => (
                            <AccordionItem key={index} value={index}>
                                <AccordionHeader className={styles.accordionHeader}>{folder}</AccordionHeader>
                                <AccordionPanel>
                                    <AccordionPanelContentChirdren parentFoldername={`${parentFoldername}${folderName}/`} folderName={folder} />
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                </Accordion>
            </div>
        );
    }
};

export default AccordionPanelContent;
