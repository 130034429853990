import React, { useState } from "react";

import { IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { DirectionalHint } from "@fluentui/react/lib/Callout";
import { useConst, useBoolean, useId } from "@fluentui/react-hooks";

import { Info20Regular } from "@fluentui/react-icons";

import styles from "./ReferenceIndexes.module.css";
import { useMsal } from "@azure/msal-react";

const ReferenceIndexes = ({
    referenceIndex,
    setReferenceIndex,
    referenceIndexesItems,
    disabled
}: {
    referenceIndex: string;
    setReferenceIndex: (referenceIndex: string) => void;
    referenceIndexesItems: { id: string; indexName: string; displayName: string }[];
    disabled: boolean;
}) => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const buttonId = useId("targetButton");
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

    const menuProps = useConst<IContextualMenuProps>(() => ({
        shouldFocusOnMount: true,
        items: referenceIndexesItems.map(item => ({
            key: item.id,
            text: item.displayName,
            onClick: () => setReferenceIndex(item.indexName)
        }))
    }));

    return (
        <div className={styles.referenceIndexContainer}>
            {activeAccount && (
                <>
                    <div className={styles.referenceIndexTitle}>
                        <p>問い合わせのカテゴリを選択</p>
                    </div>
                    <div className={styles.buttonContainer}>
                        <DefaultButton
                            text={referenceIndexesItems.find(item => item.indexName === referenceIndex)?.displayName}
                            menuProps={menuProps}
                            className={styles.referenceIndexButton}
                            disabled={disabled}
                        />
                        <Info20Regular id={buttonId} onClick={toggleTeachingBubbleVisible} className={styles.infoButton} />
                        {teachingBubbleVisible && (
                            <TeachingBubble
                                calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                                target={`#${buttonId}`}
                                isWide={true}
                                hasCloseButton={true}
                                closeButtonAriaLabel="Close"
                                onDismiss={toggleTeachingBubbleVisible}
                                headline="カテゴリを選択して回答の精度を高めることができます"
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ReferenceIndexes;
