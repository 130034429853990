import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./NavMenu.module.css";
import { useLogin } from "../../authConfig";
import { LoginButton } from "../LoginButton";
import { useMsal } from "@azure/msal-react";

const adminGroupId = import.meta.env.VITE_REACT_APP_ADMIN_SECURITY_GROUP_ID;

const NavMenu = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userGroups = activeAccount?.idTokenClaims?.groups || [];
    const isAuthGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(adminGroupId) : false;

    const [isOpen, setIsOpen] = React.useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

    const variants = {
        open: { opacity: 1, x: 0, zIndex: 1000 },
        closed: { opacity: 0, x: "100%", zIndex: 1000 }
    };

    return (
        <div className={styles.navMenuContainer}>
            {isMobile ? (
                <div className={styles.mobileNavMenuContainer}>
                    <div className={styles.hamburgerContainer}>
                        <div onClick={() => setIsOpen(!isOpen)}>
                            <div className={`${styles.hamburgerIcon} ${isOpen ? styles.open : ""}`}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                className={styles.mobileNavMenu}
                                initial={"closed"}
                                animate={isOpen ? "open" : "closed"}
                                variants={variants}
                                exit={"closed"}
                                transition={{ type: "tween", ease: "easeInOut", duration: 0.3 }}
                            >
                                <ul className={styles.mobileHeaderNavList}>
                                    <li>
                                        <NavLink
                                            to="/chat"
                                            onClick={() => setIsOpen(!isOpen)}
                                            className={({ isActive }) => (isActive ? styles.mobileHeaderNavPageLinkActive : styles.mobileHeaderNavPageLink)}
                                        >
                                            Kyo-kun
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/chatgpt"
                                            onClick={() => setIsOpen(!isOpen)}
                                            className={({ isActive }) => (isActive ? styles.mobileHeaderNavPageLinkActive : styles.mobileHeaderNavPageLink)}
                                        >
                                            ChatGPT
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/file-list"
                                            onClick={() => setIsOpen(!isOpen)}
                                            className={({ isActive }) => (isActive ? styles.mobileHeaderNavPageLinkActive : styles.mobileHeaderNavPageLink)}
                                        >
                                            File List
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/chatlog"
                                            onClick={() => setIsOpen(!isOpen)}
                                            className={({ isActive }) => (isActive ? styles.mobileHeaderNavPageLinkActive : styles.mobileHeaderNavPageLink)}
                                        >
                                            Chat Log
                                        </NavLink>
                                    </li>
                                    {isAuthGroupIdInGroups && (
                                        <li>
                                            <NavLink
                                                to="/news-management"
                                                onClick={() => setIsOpen(!isOpen)}
                                                className={({ isActive }) => (isActive ? styles.mobileHeaderNavPageLinkActive : styles.mobileHeaderNavPageLink)}
                                            >
                                                News
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                                {useLogin && (
                                    <div onClick={() => setIsOpen(!isOpen)}>
                                        <LoginButton />
                                    </div>
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            ) : (
                <>
                    <nav className={styles.headerNavContainer}>
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink to="/chat" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Kyo-kun
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/chatgpt" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    ChatGPT
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/file-list" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    File List
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/chatlog" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Chat Log
                                </NavLink>
                            </li>
                            {isAuthGroupIdInGroups && (
                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink
                                        to="/news-management"
                                        onClick={() => setIsOpen(!isOpen)}
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                    >
                                        News
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                    {useLogin && <LoginButton />}
                </>
            )}
        </div>
    );
};

export default NavMenu;
