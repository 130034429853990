export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export const enum GPT4VInput {
    TextAndImages = 'textAndImages',
    Images = 'images',
    Texts = 'texts',
  }

export const enum VectorFieldOptions {
    Embedding = 'embedding',
    ImageEmbedding = 'imageEmbedding',
    Both = 'both',
  }
  

  export type ChatAppRequestOverrides = {
    retrieval_mode?: RetrievalMode
    semantic_ranker?: boolean
    semantic_captions?: boolean
    exclude_category?: string
    top?: number
    temperature?: number
    prompt_template?: string
    prompt_template_prefix?: string
    prompt_template_suffix?: string
    suggest_followup_questions?: boolean
    use_oid_security_filter?: boolean
    use_groups_security_filter?: boolean
    use_gpt4v?: boolean
    gpt4v_input?: GPT4VInput
    vector_fields?: VectorFieldOptions[]
  }

export type ResponseMessage = {
    content: string;
    role: string;
};

export type ResponseContext = {
    thoughts: string | null;
    data_points: string[];
    followup_questions: string[] | null;
};

export type ResponseChoice = {
    index: number;
    message: ResponseMessage;
    context: ResponseContext;
    session_state: boolean;
    openai_uuid: string;
};

export type ChatAppResponseOrError = {
    choices?: ResponseChoice[];
    error?: string;
};

export type ChatAppResponse = {
    message: ResponseMessage;
    delta: ResponseMessage;
    context: ResponseContext;
    session_state: any;
};

export type ChatAppRequestContext = {
    overrides?: ChatAppRequestOverrides;
};

export type ImageData = {
    data: string;
    mime_type: string;
};

export type ChatAppRequest = {
    index_name: string;
    gpt_deployment: string;
    messages: ResponseMessage[];
    context?: ChatAppRequestContext;
    stream?: boolean;
    session_state: boolean;
    image?: ImageData;
};

export type ChatlogType = {
    user: string;
    input: string;
    response: string;
    approach: string;
    created_at: string;
    response_generate_input_tokens?: number;
    response_generate_output_tokens?: number;
    query_generate_input_tokens?: number;
    query_generate_output_tokens?: number;
    summary_generate_input_tokens?: number;
    summary_generate_output_tokens?: number;
    feedback?: string;
    feedback_comment?: string;
};

export type ChatlogIndividualByDate = {
    [date: string]: ChatlogType[];
};

export type ChatlogAllType = {
    [date: string]: ChatlogType[];
};

export type NewsType = {
    id: number;
    content: string;
    user: string;
    createdAt: string;
    updatedAt: string;
    isDisplayed: boolean;
};

export type CreateNewsType = {
    content: string;
    user: string;
    isDisplayed: boolean;
};

export type UpdateNewsType = {
    id: number;
    content: string;
    user: string;
    isDisplayed: boolean;
    isDeleted: boolean;
};

export type WriteFeedbackType = {
    openai_uuid?: string;
    feedback: string;
    feedback_comment: string;
};

export type BlobSearchResult = {
    // '@search.score': number;
    metadata_content_type: string;
    metadata_storage_file_extension: string;
    metadata_storage_name: string;
    metadata_storage_path: string;
    metadata_title: string;
};

export type BlobSearchResponse = {
    // '@odata.context': string;
    // '@odata.count': number;
    value: BlobSearchResult[];
};

export type BlobSuggestResult = {
    // '@search.text': string;
    metadata_storage_name: string;
    metadata_storage_path: string;
};

export type BlobSuggestResponse = {
    // '@odata.context': string;
    value: BlobSuggestResult[];
};
