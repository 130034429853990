import React, { useEffect, useRef, useState } from "react";
import useSWR, { mutate } from "swr";
import styles from "./NewsTableAdmin.module.css";
import { NewsType } from "../../api/models";
import { getNewsAdmin, updateNews, createNews } from "../../api";
import { useMsal } from "@azure/msal-react";
import Loader from "../Loader/Loader";
import { DocumentEdit16Regular, Delete16Regular, TextBulletListTree16Filled } from "@fluentui/react-icons";
import { TooltipHost, DirectionalHint, ITooltipHostStyles } from "@fluentui/react";

import moment from "moment-timezone";

const NewsListAdmin = () => {
    const { instance } = useMsal();
    const [editingId, setEditingId] = useState<number | undefined>();
    const [content, setContent] = useState("");
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
    const menuRef = useRef<HTMLDivElement>(null);

    const { data: news, error, isLoading } = getNewsAdmin(instance.getActiveAccount()?.idToken);

    const toggleMenu = (id: number) => {
        setSelectedItemId(id);
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setShowMenu(false);
            }
        };

        if (showMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMenu]);

    const handleEdit = (id: number, content: string, isDisplayed: boolean) => {
        setEditingId(id);
        setContent(content);
        setIsDisplayed(isDisplayed);
    };

    const handleUpdate = async () => {
        const idToken = await instance.acquireTokenSilent({ scopes: ["User.Read"] });
        if (editingId) await updateNews({ id: editingId, content, isDisplayed, user: "", isDeleted: false }, idToken.accessToken);
        mutate("/news_admin");
        setEditingId(undefined);
        setShowMenu(false);
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm("本当に削除しますか？(削除したデータは復元できません)");
        if (!confirmDelete) return;

        const idToken = await instance.acquireTokenSilent({ scopes: ["User.Read"] });
        if (selectedItemId) {
            try {
                await updateNews({ id: selectedItemId, content, isDisplayed, user: "", isDeleted: true }, idToken.accessToken);
                mutate("/news_admin");
                setEditingId(undefined);
                setShowMenu(false);
            } catch (error) {
                console.log(error);
            }
        }
    };

    if (isLoading) return <Loader />;
    if (error) return <div>Error</div>;

    return (
        <div className={styles.newsListWrapper}>
            <table className={styles.table}>
                <thead className={styles.theader}>
                    <tr className={styles.item_record_head}>
                        <th className={styles.item_content_head}>内容</th>
                        <th className={styles.item_isDisplayed_head}>公開ステータス</th>
                        <th className={styles.item_date_head}>作成日</th>
                        <th className={styles.item_date_head}>最終更新日</th>
                        <th className={styles.item_edit_head}>編集</th>
                    </tr>
                </thead>
                <tbody>
                    {news &&
                        news.map((item: NewsType) =>
                            editingId === item.id ? (
                                <tr key={item.id} className={styles.editingItem}>
                                    <td className={styles.item_content}>
                                        <textarea value={content} onChange={e => setContent(e.target.value)} />
                                    </td>
                                    <td className={styles.item_isDisplayed}>
                                        <p onClick={() => setIsDisplayed(true)} className={`${isDisplayed ? styles.isDisplayed : ""}`}>
                                            公開中
                                        </p>
                                        <p onClick={() => setIsDisplayed(false)} className={`${!isDisplayed ? styles.isNotDisplayed : ""}`}>
                                            非公開
                                        </p>
                                    </td>
                                    <td className={styles.item_date}>
                                        <p>{moment(item.createdAt).tz("Europe/London").format("YYYY/MM/DD HH:mm")}</p>
                                    </td>
                                    <td className={styles.item_date}>
                                        <p>{moment(item.updatedAt).tz("Europe/London").format("YYYY/MM/DD HH:mm")}</p>
                                    </td>
                                    <td className={styles.item_updateButtonContainer}>
                                        <button onClick={() => setEditingId(undefined)} className={styles.cancelButton}>
                                            キャンセル
                                        </button>
                                        <button onClick={handleUpdate} className={styles.updateButton}>
                                            更新
                                        </button>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={item.id} className={styles.item_record}>
                                    <td className={styles.item_content}>
                                        <p>{item.content}</p>
                                    </td>
                                    <td className={styles.item_isDisplayed}>
                                        {item.isDisplayed ? <p className={styles.isDisplayed}>公開中</p> : <p className={styles.isNotDisplayed}>非公開</p>}
                                    </td>
                                    <td className={styles.item_date}>
                                        <p>{moment(item.createdAt).tz("Europe/London").format("YYYY/MM/DD HH:mm")}</p>
                                    </td>
                                    <td className={styles.item_date}>
                                        <p>{moment(item.updatedAt).tz("Europe/London").format("YYYY/MM/DD HH:mm")}</p>
                                    </td>
                                    <td className={styles.item_edit}>
                                        <div className={styles.editButtonContainer}>
                                            {!showMenu && <TextBulletListTree16Filled className={styles.editIcon} onClick={() => toggleMenu(item.id)} />}
                                            {showMenu && selectedItemId === item.id && (
                                                <div className={styles.menuPopup} ref={menuRef}>
                                                    <button onClick={() => handleEdit(item.id, item.content, item.isDisplayed)} className={styles.editButton}>
                                                        <DocumentEdit16Regular />
                                                        編集
                                                    </button>
                                                    <button onClick={handleDelete} className={styles.deleteButton}>
                                                        <Delete16Regular />
                                                        削除
                                                    </button>
                                                    <button onClick={() => setShowMenu(false)}>キャンセル</button>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )
                        )}
                </tbody>
            </table>
            {news && news.length === 0 && <p className={styles.noNews}>作成したお知らせはありません</p>}
        </div>
    );
};

export default NewsListAdmin;
