import styles from "./Chatlog.module.css";
import ChatlogIndividual from "../../components/ChatlogIndividual/ChatlogIndividual";

import { Pivot, PivotItem } from "@fluentui/react";
import { LoginButton } from "../../components/LoginButton";
import { useMsal } from "@azure/msal-react";
import ChatlogAll from "../../components/ChatlogAll/ChatlogAll";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const adminGroupId = import.meta.env.VITE_REACT_APP_ADMIN_SECURITY_GROUP_ID;
const commonGroupId = import.meta.env.VITE_REACT_APP_COMMON_SECURITY_GROUP_ID;

const Chatlog = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const userGroups = activeAccount?.idTokenClaims?.groups || [];
    const isAuthGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(adminGroupId) : false;
    const isCommonGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(commonGroupId) : false;

    const chats = useSelector((state: RootState) => state.chat);

    if (activeAccount && !isCommonGroupIdInGroups && activeAccount && !isAuthGroupIdInGroups) {
        return (
            <div className={styles.deniedContainer}>
                <h1 className={styles.title}>Chatlog</h1>
                <div className={styles.loginMessageContainer}>
                    <p>このリソースを表示するのに十分な権限がありません。</p>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Chatlog</h1>
            {activeAccount ? (
                <Pivot>
                    <PivotItem headerText="Your Chatlog">
                        <ChatlogIndividual />
                    </PivotItem>
                    {isAuthGroupIdInGroups && (
                        <PivotItem headerText="All Chatlog">
                            <ChatlogAll />
                        </PivotItem>
                    )}
                </Pivot>
            ) : (
                <div className={styles.loginMessageContainer}>
                    <p>チャットログを閲覧するにはログインしてください。</p>
                    <LoginButton />
                </div>
            )}
        </div>
    );
};
export default Chatlog;
