import React, { useState } from "react";
import styles from "./Example.module.css";
import { exampleList, ExampleListType, ListType } from "./ExampleList.constant";
import { Backspace20Filled } from "@fluentui/react-icons";
import { motion } from "framer-motion";
import { fadeIn, fadeSlideIn } from "../../utils/motion";

interface Props {
    category: string;
    onSend: (question: string) => void;
}

const ExampleListDescription = ({ category, onSend }: Props) => {
    const [isListOpen, setIsListOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ListType>();
    const list = (exampleList as ExampleListType)[category];

    const delayIncrement = 0.1;

    const handleItemClick = (item: ListType) => {
        setSelectedItem(item);
        setIsListOpen(true);
    };

    const handleClose = () => {
        setSelectedItem(undefined);
        setIsListOpen(false);
    };

    const handleExampleClick = (example: ListType) => {
        onSend(example.value);
    };

    return (
        <div>
            {!isListOpen && (
                <ul className={styles.rulesList}>
                    {list.map((x, i) => (
                        <motion.li
                            key={i}
                            onClick={() => handleItemClick(x)}
                            className={styles.rulesListLi}
                            variants={fadeSlideIn("up", "tween", i * delayIncrement, 0.5)}
                        >
                            <p>{x.text}</p>
                        </motion.li>
                    ))}
                </ul>
            )}
            {isListOpen && selectedItem && (
                <div className={styles.rulesListDescription}>
                    <div className={styles.selectedListHeaderContainer}>
                        <li className={styles.selectedListHeader} onClick={handleClose}>
                            <p>{selectedItem.text}</p>
                        </li>
                        <div className={styles.backButton} onClick={handleClose}>
                            <Backspace20Filled color="black" />
                        </div>
                    </div>
                    {selectedItem.children && (
                        <ul className={styles.selectedList}>
                            {selectedItem.children.map((x, i) => (
                                <motion.li
                                    key={i}
                                    className={styles.selectedListItem}
                                    onClick={() => handleExampleClick(x)}
                                    variants={fadeSlideIn("up", "tween", i * delayIncrement, 0.5)}
                                >
                                    <p>{x.text}</p>
                                </motion.li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default ExampleListDescription;
