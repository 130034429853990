import React, { useState } from "react";
import { ChatlogType } from "../../api/models";
import { getHeaders } from "../../api";
import styles from "./ChatlogIndividual.module.css";
import moment from "moment";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from "@fluentui/react-components";
import useSWR from "swr";
import { useMsal } from "@azure/msal-react";
import { getToken, useLogin } from "../../authConfig";
import Loader from "../Loader/Loader";
import { DatabaseSearch20Regular } from "@fluentui/react-icons";
import { SearchBox } from "@fluentui/react";

const Chatlog = () => {
    const client = useLogin ? useMsal().instance : undefined;
    const [openItems, setOpenItems] = React.useState<number[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [url, setUrl] = useState<string>("/chatlog");

    const handleToggle: AccordionToggleEventHandler<number> = (e, data) => {
        setOpenItems(data.openItems);
    };

    const handleInputChange = async (event: any, newValue?: string) => {
        setSearchText(newValue || "");
    };

    const handleSearch = () => {
        if (searchText === "") {
            setUrl("/chatlog");
        } else {
            setUrl(`/search_chatlog?search_text=${searchText}`);
        }
    };

    const fetcher = async (url: string) => {
        const token = client ? await getToken(client) : undefined;
        if (token == undefined) {
            throw new Error("Token is undefined");
        } else {
            return fetch(url, {
                method: "GET",
                headers: getHeaders(token ? token.accessToken : undefined)
            }).then(res => res.json());
        }
    };

    const { data: chatlogs, error, isLoading } = useSWR("/chatlog", fetcher);

    const sortedChatlogs = chatlogs && Object.entries(chatlogs).sort(([chatlogs1], [chatlogs2]) => chatlogs2.localeCompare(chatlogs1));

    if (error) {
        if (error.message === "Token is undefined") {
            return (
                <div className={styles.LoadingContainer}>
                    <Loader />
                    <p className={styles.LoadingMessage}>Loading...</p>
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.searchContainer}>
                        <p className={styles.searchText}>Search Your Log:</p>
                        <div className={styles.searchInputContainer}>
                            <SearchBox
                                placeholder="Search"
                                onSearch={handleSearch}
                                onChange={handleInputChange}
                                value={searchText}
                                className={styles.searchBox}
                            />
                            <button className={styles.searchButton} onClick={handleSearch}>
                                <DatabaseSearch20Regular />
                                Search
                            </button>
                        </div>
                    </div>
                    <div>failed to load</div>
                </>
            );
        }
    }

    if (isLoading) {
        return (
            <div className={styles.LoadingContainer}>
                <Loader />
                <p className={styles.LoadingMessage}>Loading...</p>
            </div>
        );
    }

    if (sortedChatlogs.length === 0) {
        return (
            <>
                <div className={styles.searchContainer}>
                    <p className={styles.searchText}>Search Your Log:</p>
                    <div className={styles.searchInputContainer}>
                        <SearchBox placeholder="Search" onSearch={handleSearch} onChange={handleInputChange} value={searchText} className={styles.searchBox} />
                        <button className={styles.searchButton} onClick={handleSearch}>
                            <DatabaseSearch20Regular />
                            Search
                        </button>
                    </div>
                </div>
                <div className={styles.LoadingContainer}>
                    <p className={styles.LoadingMessage}>No logs found</p>
                </div>
            </>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.searchContainer}>
                <p className={styles.searchText}>Search Your Log:</p>
                <div className={styles.searchInputContainer}>
                    <SearchBox placeholder="Search" onSearch={handleSearch} onChange={handleInputChange} value={searchText} className={styles.searchBox} />
                    <button className={styles.searchButton} onClick={handleSearch}>
                        <DatabaseSearch20Regular />
                        Search
                    </button>
                </div>
            </div>
            <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                {sortedChatlogs.map(([date, logs]: [string, ChatlogType[]], index: number) => (
                    <AccordionItem key={index} value={date} className={styles.logItemBox}>
                        <AccordionHeader>{date}</AccordionHeader>
                        <AccordionPanel>
                            {logs.map((log, logIndex) => (
                                <div key={logIndex} className={styles.logItemBox}>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>Time Stamp: </p>
                                        <p className={styles.logValue}>{new Date(log.created_at).toISOString().replace(/T/, " ").replace(/\..+/, "")}</p>
                                    </div>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>Input:</p>
                                        <p className={styles.logValue}>{log.input}</p>
                                    </div>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>Response:</p>
                                        <p className={styles.logValue}>{log.response}</p>
                                    </div>
                                </div>
                            ))}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default Chatlog;
