import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatAppResponse } from '../../api';

const initialState: [string, ChatAppResponse, string][] = [];

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addChat: (state, action: PayloadAction<[string, ChatAppResponse, string]>) => {
      state.push(action.payload);
    },
    clearChat: (state) => {
      state.splice(0, state.length)
    },
  },
});

export const { addChat, clearChat } = chatSlice.actions;

export default chatSlice.reducer;
