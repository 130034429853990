import React from "react";
import { useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";
import Loader from "../Loader/Loader";

import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import Feedback from "../Feedback/Feedback";
import { motion } from "framer-motion";
import { fadeSlideIn } from "../../utils/motion";

interface Props {
    openai_uuid: string;
    onFeedbackClicked?: () => void;
    approach: "chat" | "chatgpt";
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    onAnalysisPanelIsOpen?: () => void;
    isFeedbackSent?: boolean;
    updateFeedbackSentStatus: (uuid: string, sent: boolean) => void;
    isLatestAnswer: boolean;
}

export const Answer = ({
    openai_uuid,
    approach,
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onAnalysisPanelIsOpen,
    isFeedbackSent,
    updateFeedbackSentStatus,
    isLatestAnswer
}: Props) => {
    const followupQuestions = answer.context?.followup_questions;
    const messageContent = answer.message.content;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, isLatestAnswer, onCitationClicked), [answer, isStreaming]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    const handleFeedbackClicked = () => {
        updateFeedbackSentStatus(openai_uuid, true);
    };

    return (
        <motion.div className={styles.answerWrapper} variants={fadeSlideIn("up", "tween", 0, 0.3)}>
            <div className={styles.container}>
                <div className={styles.kdbContainer}>
                    {approach == "chat" && (
                        <div className={styles.gptUserContainer}>
                            <div className={styles.kdbImgContainer}>
                                <img src="../../assets/kyoden-aoai-char.png" />
                            </div>
                            <p className={styles.kdbName}>キョウくん</p>
                        </div>
                    )}
                    {approach == "chatgpt" && (
                        <div className={styles.gptUserContainer}>
                            <div className={styles.opneaiLogoContainer}>
                                <img src="../../assets/openai-logomark.png" />
                            </div>
                            <p className={styles.kdbName}>ChatGPT</p>
                        </div>
                    )}
                </div>
            </div>
            <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <div>
                            {/* <IconButton
                                style={{ color: "white" }}
                                iconProps={{ iconName: "Lightbulb" }}
                                title="Show thought process"
                                ariaLabel="Show thought process"
                                onClick={() => onThoughtProcessClicked()}
                                disabled={!answer.choices[0].context.thoughts?.length}
                                className={styles.iconButton}
                            /> */}
                            <IconButton
                                style={{ color: "white" }}
                                iconProps={{ iconName: "ClipboardList" }}
                                title="Show supporting content"
                                ariaLabel="Show supporting content"
                                onClick={() => onSupportingContentClicked()}
                                // disabled={!answer.context.data_points}
                                className={styles.iconButton}
                            />
                        </div>
                    </Stack>
                </Stack.Item>

                {answer.message.content === "powerbi" ? (
                    <div className={styles.answerTextPowerBi}>
                        こちらからご確認ください
                        <br />
                        <iframe
                            title="Daily_Report_Digest"
                            // width="600"
                            // height="373.5"
                            width="700"
                            height="500"
                            src="https://app.powerbi.com/view?r=eyJrIjoiYzE2MWMyN2MtYzhlYy00YWVjLWI4ZDMtNGVjZjYxZGQyOWIzIiwidCI6ImJjODhhOTE2LWNhNDAtNGEzYS1iOTA0LTRmNDU0YmM0YjAwMSIsImMiOjEwfQ%3D%3D"
                            style={{ border: "0" }}
                            allowFullScreen={true}
                        ></iframe>
                        <br />
                        より詳細なレポートを閲覧するにはPower BI Daily レポートにアクセスしてください。
                        <a href="https://blue-flower-0ee85a800.3.azurestaticapps.net/" target="_blank" className={styles.powerbi}>
                            デイリー進捗レポートへアクセスする
                        </a>
                    </div>
                ) : (
                    <Stack.Item grow>
                        <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                    </Stack.Item>
                )}

                {!!parsedAnswer.citations.length && (
                    <Stack.Item>
                        <Stack wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>【引用元文書】</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                return (
                                    <ul key={i} className={styles.citationListUl}>
                                        <li className={styles.citationListLi}>
                                            <a
                                                key={i}
                                                className={styles.citation}
                                                title={x}
                                                onClick={event => {
                                                    onCitationClicked(path);
                                                    if (onAnalysisPanelIsOpen) {
                                                        onAnalysisPanelIsOpen();
                                                    }
                                                }}
                                            >
                                                {`${++i}. ${x}`}
                                            </a>
                                        </li>
                                    </ul>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}

                {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                    <Stack.Item>
                        <Stack wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            <span className={styles.followupQuestionLearnMore}>【追加の質問】</span>
                            {followupQuestions.map((x, i) => {
                                return (
                                    <ul key={i} className={styles.followupQuestionsListUl}>
                                        <li className={styles.followupQuestionsListLi}>
                                            <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                                {`Q${i + 1}. ${x}`}
                                            </a>
                                        </li>
                                    </ul>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
                {approach == "chat" && openai_uuid && !isFeedbackSent && !isStreaming && (
                    <Feedback openai_uuid={openai_uuid} onFeedbackClicked={handleFeedbackClicked} updateFeedbackSentStatus={updateFeedbackSentStatus} />
                )}
            </Stack>
        </motion.div>
    );
};
