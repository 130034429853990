import React from "react";
import { ChatlogType } from "../../api/models";
import styles from "./ChatlogAll.module.css";
import moment from "moment";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from "@fluentui/react-components";
import useSWR from "swr";
import Loader from "../Loader/Loader";

import { exportToExcel, getCurrentFormattedTimestamp } from "./ExcelUtils";

const fetcher = (url: string) => fetch(url).then(res => res.json());

const ChatlogAll = () => {
    const [openItems, setOpenItems] = React.useState<number[]>([]);
    const handleToggle: AccordionToggleEventHandler<number> = (e, data) => {
        setOpenItems(data.openItems);
    };

    const { data: allChatlogs, error, isLoading } = useSWR("/allchatlogs", fetcher);

    const sortedChatlogs = allChatlogs && Object.entries(allChatlogs).sort(([allChatlogs1], [allChatlogs2]) => allChatlogs2.localeCompare(allChatlogs1));

    if (error) return <div>failed to load</div>;

    if (isLoading) {
        return (
            <div className={styles.LoadingContainer}>
                <Loader />
                <p className={styles.LoadingMessage}>Loading...</p>
            </div>
        );
    }

    return (
        <>
            <div className={styles.downloadButtonContainer} onClick={() => exportToExcel(allChatlogs, `chatlogs_${getCurrentFormattedTimestamp()}`)}>
                <p className={styles.downloadText}>Download Logs</p>
                <div className={styles.xlsxIconContainer}>
                    <img src="/assets/xlsx.png" alt="download" className={styles.downloadIcon} />
                </div>
            </div>
            <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                {sortedChatlogs.map(([date, logs]: [string, ChatlogType[]], index: number) => (
                    <AccordionItem key={index} value={date} className={styles.logItemBox}>
                        <AccordionHeader>{date}</AccordionHeader>
                        <AccordionPanel>
                            {logs.map((log, logIndex) => (
                                <div key={logIndex} className={styles.logItemBox}>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>User:</p>
                                        <p className={styles.logValue}>{log.user}</p>
                                    </div>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>Time Stamp: </p>
                                        <p className={styles.logValue}>{new Date(log.created_at).toISOString().replace(/T/, " ").replace(/\..+/, "")}</p>
                                        {/* <p className={styles.logValue}>
                                            {new Date(log.created_at)
                                                .toLocaleString("ja-JP", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                    hour12: false
                                                })
                                                .replace(/\//g, "-")}
                                        </p> */}
                                    </div>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>Input:</p>
                                        <p className={styles.logValue}>{log.input}</p>
                                    </div>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>Response:</p>
                                        <p className={styles.logValue}>{log.response}</p>
                                    </div>
                                    <div className={styles.logItem}>
                                        <p className={styles.logKey}>Approach:</p>
                                        <p className={styles.logValue}>
                                            {(() => {
                                                if (log.approach.startsWith("chatgpt")) {
                                                    return log.approach;
                                                } else if (log.approach.startsWith("chat")) {
                                                    return "kyo-kun";
                                                } else {
                                                    return;
                                                }
                                            })()}
                                        </p>
                                    </div>
                                    {log.feedback && (
                                        <div className={styles.logItem}>
                                            <p className={styles.logKey}>Feedback:</p>
                                            <p className={styles.logValue}>
                                                {(() => {
                                                    switch (log.feedback) {
                                                        case "0-good":
                                                            return "良い";
                                                        case "1-bad_unclear":
                                                            return "不明瞭";
                                                        case "2-bad_inaccurate":
                                                            return "不正確";
                                                        case "3-bad_unrelated":
                                                            return "関係がない";
                                                        case "4-bad_other":
                                                            return "悪い（その他）";
                                                        default:
                                                            return;
                                                    }
                                                })()}
                                            </p>
                                        </div>
                                    )}
                                    {log.feedback_comment && (
                                        <div className={styles.logItem}>
                                            <p className={styles.logKey}>Feedback Comment:</p>
                                            <p className={styles.logValue}>{log.feedback_comment}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </>
    );
};

export default ChatlogAll;
