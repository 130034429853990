import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { msalConfig, useLogin } from "./authConfig";
import store from "./redux/store";
import { Provider } from "react-redux";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import ChatGPT from "./pages/chatgpt/ChatGPT";
import Chatlog from "./pages/chatlog/Chatlog";
import FileList from "./pages/filelist/FileList";
import Top from "./pages/top/Top";
import NewsManagement from "./pages/newsmanagemnet/NewsManagement";
import UserManual from "./pages/usermanual/UserManual";
import NewsCreate from "./pages/newscreate/NewsCreate";

var layout;
if (useLogin) {
    var msalInstance = new PublicClientApplication(msalConfig);

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    layout = (
        <MsalProvider instance={msalInstance}>
            <Layout />
        </MsalProvider>
    );
} else {
    layout = <Layout />;
}

initializeIcons();

const router = createHashRouter([
    {
        path: "/",
        element: layout,
        children: [
            {
                index: true,
                element: <Top />
            },
            {
                path: "chat",
                element: <Chat />
            },
            {
                path: "chatgpt",
                element: <ChatGPT />
            },
            {
                path: "chatlog",
                element: <Chatlog />
            },
            {
                path: "file-list",
                element: <FileList />
            },
            {
                path: "news-management",
                element: <NewsManagement />
            },
            {
                path: "news-create",
                element: <NewsCreate />
            },
            {
                path: "user-manual",
                element: <UserManual />
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);
