import { Outlet, Link } from "react-router-dom";

import styles from "./Layout.module.css";

import NavMenu from "../../components/NavMenu/NavMenu";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img src="/assets/kyoden-ai-platform-1.png" className={styles.headerLogo} alt="Kyo-kun" />
                    </Link>
                    <NavMenu />
                </div>
            </div>
            <Outlet />
        </div>
    );
};

export default Layout;
