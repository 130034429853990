import { ChatlogAllType } from "../../api/models";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export const convertDataToSheetFormat = (data: ChatlogAllType) => {
    let convertedData: {
        user: string;
        input: string;
        response: string;
        approach: string;
        response_generate_input_tokens?: number | undefined;
        response_generate_output_tokens?: number | undefined;
        query_generate_input_tokens?: number | undefined;
        query_generate_output_tokens?: number | undefined;
        summary_generate_input_tokens?: number | undefined;
        summary_generate_output_tokens?: number | undefined;
        feedback?: string | undefined;
        feedback_comment?: string | undefined;
        created_at: string;
    }[] = [];

    for (const [date, logs] of Object.entries(data)) {
        logs.forEach(log => {
            const createdAt = new Date(log.created_at);
            const formattedCreatedAt = new Date(createdAt).toISOString().replace(/T/, " ").replace(/\..+/, "");

            let approachScript = log.approach;
            if (log.approach === "chat") {
                approachScript = "kyo-kun";
            }

            convertedData.push({
                created_at: formattedCreatedAt,
                approach: approachScript,
                input: log.input,
                response: log.response,
                user: log.user,
                response_generate_input_tokens: log.response_generate_input_tokens,
                response_generate_output_tokens: log.response_generate_output_tokens,
                query_generate_input_tokens: log.query_generate_input_tokens,
                query_generate_output_tokens: log.query_generate_output_tokens,
                summary_generate_input_tokens: log.summary_generate_input_tokens,
                summary_generate_output_tokens: log.summary_generate_output_tokens,
                feedback: log.feedback,
                feedback_comment: log.feedback_comment
            });
        });
    }
    convertedData.sort((a, b) => b.created_at.localeCompare(a.created_at));

    return convertedData;
};

export const exportToExcel = (jsonData: ChatlogAllType, fileName: string) => {
    const convertedData = convertDataToSheetFormat(jsonData);
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(convertedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
};

export const getCurrentFormattedTimestamp = () => {
    const now = new Date();
    return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}_${now
        .getHours()
        .toString()
        .padStart(2, "0")}-${now.getMinutes().toString().padStart(2, "0")}-${now.getSeconds().toString().padStart(2, "0")}`;
};
