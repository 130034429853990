import { configureStore } from '@reduxjs/toolkit'
import chatReducer from './slice/chatSlice';
import chatGptReducer from './slice/chatGptSlice';

const store = configureStore({
  reducer: {
    chat: chatReducer,
    chatGpt: chatGptReducer,
  },
});

export default store
export type RootState = ReturnType<typeof store.getState>