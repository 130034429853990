import React from "react";

import styles from "./FileIcon.module.css";

const FileIcon = ({ fileName }: { fileName: string }) => {
    const extension = fileName.split(".").pop();

    switch (extension) {
        case "pdf":
        case "PDF":
            return <img src="/assets/pdf.png" alt="pdf" className={styles.fileIcon} />;
        case "docx":
        case "doc":
            return <img src="/assets/word.png" alt="word" className={styles.fileIcon} />;
        case "xlsx":
        case "xls":
            return <img src="/assets/excel.png" alt="excel" className={styles.fileIcon} />;
        case "pptx":
        case "ppt":
            return <img src="/assets/powerpoint.png" alt="powerpoint" className={styles.fileIcon} />;
        default:
            return <img src="/assets/folder.png" alt="file" className={styles.fileIcon} />;
    }
};

export default FileIcon;
