export const fadeSlideIn = (direction: string, type: string, delay: number, duration: number) => ({
  hidden: {
    opacity: 0,
    x: direction === 'left' ? '-100%' : direction === 'right' ? '100%' : 0,
    y: direction === 'up' ? '100%' : direction === 'down' ? '100%' : 0,
  },
  show: {
    opacity: 1,
    x: 0,
    y: 0,
    zIndex: 1,
    transition: {
      type,
      delay,
      duration,
      ease: [0.08, 0.96, 0.38, 0.98],
    },
  },
});

export const fadeIn = (delay: number, duration: number) => ({
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay,
      duration,
      ease: [0.08, 0.96, 0.38, 0.98],
    },
  },
});
