import { useEffect, useState } from "react";
import { Example } from "./Example";

import styles from "./Example.module.css";
import { Tooltip } from "@fluentui/react-components";
import { DocumentBulletList20Filled, Table20Regular, Chat16Regular } from "@fluentui/react-icons";
import { Backspace20Filled, AlignSpaceEvenlyHorizontal20Filled } from "@fluentui/react-icons";
import ExampleListDescription from "./ExampleListDescription";
import { QuestionInput } from "../QuestionInput/QuestionInput";

import { motion } from "framer-motion";
import { fadeIn, fadeSlideIn } from "../../utils/motion";
import { UsedTokens } from "../UsedTokens/UsedTokens";

export type ExampleModel = {
    text: string;
    value: string;
    icon: JSX.Element;
    category: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "さまざまな社内規定について知りたい",
        value: "規定や要領を探す",
        icon: <DocumentBulletList20Filled color="black" />,
        category: "rules"
    },
    {
        text: "Power BI を見たい",
        value: "power biを見たい",
        icon: <Table20Regular color="black" />,
        category: "powerbi"
    },
    {
        text: "テキストを入力する",
        value: "その他",
        icon: <Chat16Regular color="black" />,
        category: "other"
    }
];

interface Props {
    usedTokens: number;
    onSend: (question: string) => void;
    disabled: boolean;
    clearOnSend?: boolean;
    isStreaming: boolean;
}

export const ExampleList = ({ onSend, disabled, clearOnSend, usedTokens, isStreaming }: Props) => {
    const [isDescOpen, setIsDescOpen] = useState<boolean>(false);
    const [selectedExample, setSelectedExample] = useState<ExampleModel | null>(null);
    const [category, setCategory] = useState<string>("");
    const [isQuestionInputOpen, setIsQuestionInputOpen] = useState<boolean>(true);
    const [isTokenLimit, setIsTokenLimit] = useState<boolean>(false);

    const delayIncrement = 0.2;

    const handleSend = (question: string) => {
        onSend(question);
        setIsQuestionInputOpen(true);
    };

    const handleClick = (example: ExampleModel) => {
        if (example.category == "other") {
            setIsQuestionInputOpen(true);
        } else if (example.category == "powerbi") {
            handleSend(example.value);
        } else {
            setSelectedExample(example);
            setCategory(example.category);
            setIsDescOpen(true);
        }
    };
    const handleBackClick = () => {
        setSelectedExample(null);
        setCategory("");
        setIsDescOpen(false);
    };

    const handledisplayExample = () => {
        setIsQuestionInputOpen(false);
    };

    useEffect(() => {
        if (usedTokens > 50000) {
            setIsTokenLimit(true);
        }
    }, [disabled, usedTokens]);

    return (
        <>
            {isTokenLimit && (
                <>
                    <div className={styles.disabledTokensContainer}>
                        <UsedTokens usedTokens={usedTokens} />
                    </div>
                </>
            )}
            {!isTokenLimit && (
                <motion.div variants={fadeIn(0, 0.8)} initial="hidden" animate="show">
                    {isQuestionInputOpen && (
                        <>
                            <div className={styles.exampleListHeader}>
                                {isStreaming ? (
                                    <Tooltip content="Wait for Answer to complete" relationship="label">
                                        <div className={styles.displayExampleIsStreaming} onClick={undefined}>
                                            <AlignSpaceEvenlyHorizontal20Filled color="black" />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip content="Show Examples" relationship="label">
                                        <div className={styles.displayExample} onClick={!disabled ? handledisplayExample : undefined}>
                                            <AlignSpaceEvenlyHorizontal20Filled color="black" />
                                        </div>
                                    </Tooltip>
                                )}
                                <UsedTokens usedTokens={usedTokens} />
                            </div>

                            <QuestionInput
                                usedTokens={usedTokens}
                                clearOnSend={clearOnSend}
                                placeholder={"質問を入力してください。"}
                                disabled={disabled}
                                onSend={onSend}
                                isStreaming={isStreaming}
                            />
                        </>
                    )}
                </motion.div>
            )}
            {!isQuestionInputOpen && !isTokenLimit && (
                <div className={styles.exampleListContainer}>
                    <div className={styles.exampleListContainerHeader}>
                        <h2>今日は何を知りたいですか？</h2>
                    </div>
                    {!isDescOpen && (
                        <ul className={styles.examplesNavList}>
                            {EXAMPLES.map((x, i) => (
                                <motion.li key={i} variants={fadeIn(i * delayIncrement, 0.8)}>
                                    <Example text={x.text} value={x.value} icon={x.icon} onClick={() => handleClick(x)} selected={false} />
                                </motion.li>
                            ))}
                        </ul>
                    )}
                    {isDescOpen && selectedExample?.category == "rules" && (
                        <div>
                            <div className={styles.exampleListDescriptionHeader}>
                                <Example
                                    text={selectedExample?.text || ""}
                                    value={selectedExample?.value || ""}
                                    icon={selectedExample?.icon || <></>}
                                    onClick={handleBackClick}
                                    selected={true}
                                />
                                <div className={styles.backButton} onClick={handleBackClick}>
                                    <Backspace20Filled color="black" />
                                </div>
                            </div>
                            <ExampleListDescription category={category} onSend={handleSend} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
