import React from "react";
import styles from "./TopPageNews.module.css";
import useSWR from "swr";
import moment from "moment";
import Loader from "../Loader/Loader";
import { motion } from "framer-motion";
import { fadeSlideIn } from "../../utils/motion";

const TopPageNews = () => {
    const fetcher = (url: string) => fetch(url).then(res => res.json());
    const { data: news, isLoading, error } = useSWR("/news", fetcher);

    if (isLoading) return <Loader />;
    if (error) return <div>Error</div>;

    return (
        <motion.div className={styles.TopPageNewsWrapper} variants={fadeSlideIn("up", "tween", 0.8, 0.8)} initial="hidden" animate="show">
            <h2 className={styles.TopPageNewsTitle}>News</h2>

            {news.length > 0 ? (
                news.map((item: any, index: number) =>
                    index === 0 ? (
                        <div key={index} className={styles.TopPageNewsContent}>
                            <div className={styles.newestNewsContent}>
                                <p className={styles.newsDate}>{moment(item.createdAt).format("YYYY/MM/DD")}</p>
                                <p className={styles.newestNews}>NEW</p>
                            </div>
                            <p className={styles.newsContentText}>{item.content}</p>
                        </div>
                    ) : (
                        <div key={index} className={styles.TopPageNewsContent}>
                            <p className={styles.newsDate}>{moment(item.createdAt).format("YYYY/MM/DD")}</p>
                            <p className={styles.newsContentText}>{item.content}</p>
                        </div>
                    )
                )
            ) : (
                <p className={styles.noNewsContent}>最新のお知らせはありません。</p>
            )}
        </motion.div>
    );
};

export default TopPageNews;
