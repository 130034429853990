import React from "react";
import styles from "./TopPageTiles.module.css";
import { BookContacts24Regular, Chat24Regular, Search24Regular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeSlideIn } from "../../utils/motion";

const TopPageTiles = () => {
    return (
        <div className={styles.tilesWrapper}>
            <motion.div className={styles.tileBlock1} variants={fadeSlideIn("up", "tween", 0, 0.8)} initial="hidden" animate="show">
                <div className={styles.tileWrapper}>
                    <Link to="/chat" className={styles.tile1}>
                        <h2>Kyo-Kun</h2>
                        <img src="/assets/kyoden-aoai-char.png" alt="kyo-kun logo" />
                        <div className={styles.tileDescription}>
                            <p>キョウデン社内規定などの質問はこちらから</p>
                        </div>
                    </Link>
                </div>
            </motion.div>

            <div className={styles.tileBlock2}>
                <motion.div className={styles.tile2} variants={fadeSlideIn("up", "tween", 0.2, 0.8)} initial="hidden" animate="show">
                    <Link to="/chatgpt" className={styles.tileWrapper2}>
                        <div className={styles.tile2LogoContainer}>
                            <img src="/assets/openai-logomark.png" alt="chatgpt logo" className={styles.tile2Logo} />
                            <div className={styles.tileDescription}>
                                <h2>ChatGPT</h2>
                                <p>一般的なAIはこちらから</p>
                            </div>
                        </div>
                    </Link>
                </motion.div>
                <div className={styles.tileBlock2Elements}>
                    <motion.div className={styles.tileBlockContainer} variants={fadeSlideIn("up", "tween", 0.4, 0.8)} initial="hidden" animate="show">
                        <Link to="/file-list" className={styles.tile3}>
                            <div className={styles.tileTitle}>
                                <div className={styles.iconWrapper}>
                                    <Search24Regular />
                                </div>
                                <h2>File List</h2>
                            </div>
                            <div className={styles.tileDescription}>
                                <p>文書の検索はこちらから</p>
                            </div>
                        </Link>
                    </motion.div>
                    <div className={styles.tileBlock2Element3}>
                        <motion.div className={styles.tileBlockContainer} variants={fadeSlideIn("up", "tween", 0.6, 0.8)} initial="hidden" animate="show">
                            <Link to="/chatlog" className={styles.tile4}>
                                <div className={styles.tileTitle}>
                                    <div className={styles.iconWrapper}>
                                        <Chat24Regular />
                                    </div>
                                    <h2>Chat Log</h2>
                                </div>
                                <div className={styles.tileDescription}>
                                    <p>チャット履歴を参照できます</p>
                                </div>
                            </Link>
                        </motion.div>
                        <motion.div className={styles.tileBlockContainer} variants={fadeSlideIn("up", "tween", 0.8, 0.8)} initial="hidden" animate="show">
                            <Link to="/user-manual" className={styles.tile5}>
                                <div className={styles.tileTitle}>
                                    <div className={styles.iconWrapper}>
                                        <BookContacts24Regular />
                                    </div>
                                    <h2>User Manual</h2>
                                </div>
                                <div className={styles.tileDescription}>
                                    <p>チャットで欲しい返答が得られない場合はこちら</p>
                                </div>
                            </Link>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopPageTiles;
