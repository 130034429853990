import React from "react";
import styles from "./Top.module.css";
import TopPageTiles from "../../components/TopPageTiles/TopPageTiles";
import TopPageNews from "../../components/TopPageNews/TopPageNews";

const Top = () => {
    return (
        <div className={styles.topWrapper}>
            <div className={styles.topText}>
                <h1 className={styles.pageTitleText}>
                    <span>Kyoden</span> AI Platform
                </h1>
                <div className={styles.powerdByTextContainer}>
                    <p>powered by</p>
                    <img src="/assets/cosm-logo.png" alt="cosm-logo" />
                </div>
            </div>
            <TopPageTiles />
            <TopPageNews />
        </div>
    );
};

export default Top;
