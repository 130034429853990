import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import Loader from "../Loader/Loader";

export const AnswerLoading = ({ approach }: { approach: string }) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            {approach == "chat" && (
                <div className={styles.container}>
                    <div className={styles.kdbContainer}>
                        <div className={styles.kdbImgContainer}>
                            <img src="../../assets/kyoden-aoai-char.png" />
                        </div>
                        <p className={styles.kdbName}>キョウくん</p>
                        <Loader />
                    </div>
                </div>
            )}
            {approach == "chatgpt" && (
                <div className={styles.container}>
                    <div className={styles.kdbContainer}>
                        <div className={styles.opneaiLogoContainer}>
                            <img src="../../assets/openai-logomark.png" />
                        </div>
                        <p className={styles.kdbName}>Chat GPT</p>
                        <Loader />
                    </div>
                </div>
            )}
            {approach == "chat" && (
                <Stack className={styles.answerContainer} verticalAlign="space-between">
                    <Stack.Item grow>
                        <p className={styles.answerText}>
                            キョウくんが入力中
                            <span className={styles.loadingdots} />
                        </p>
                    </Stack.Item>
                </Stack>
            )}
            {approach == "chatgpt" && (
                <Stack className={styles.answerContainer} verticalAlign="space-between">
                    <Stack.Item grow>
                        <p className={styles.answerText}>
                            Chat GPTが入力中
                            <span className={styles.loadingdots} />
                        </p>
                    </Stack.Item>
                </Stack>
            )}
        </animated.div>
    );
};
