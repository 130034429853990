import React from "react";
import { useFile } from "../../api";
import styles from "./AccordionPanelContentChildren.module.css";
import { ArrowDownload16Filled } from "@fluentui/react-icons";
import FileIcon from "../FileIcon/FileIcon";

interface FilesProps {
    name: string;
    url: string;
}

const AccordionPanelContentChirdren = ({ parentFoldername, folderName }: { parentFoldername: string; folderName: string }) => {
    const { data: files, error, isLoading } = useFile(`${parentFoldername}${folderName}/`);

    if (error) return <div>Error: {error}</div>;
    if (isLoading) return <div>Loading...</div>;

    return (
        <div className={styles.listContainer}>
            {files &&
                files.map((file, index) => (
                    <div key={index} className={styles.contentItem}>
                        <div className={styles.download}>
                            <p className={styles.fileText}>
                                <FileIcon fileName={file.name} />
                                {file.name}
                            </p>
                            <a href={file.url} target="_blank" download className={styles.downloadText}>
                                Download
                                <ArrowDownload16Filled color="black" />
                            </a>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default AccordionPanelContentChirdren;
