import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatAppResponse } from '../../api';

const initialState: [string, ChatAppResponse, string, string | null][] = [];

const chatGptSlice = createSlice({
  name: 'chatGpt',
  initialState,
  reducers: {
    addChatGpt: (state, action: PayloadAction<[string, ChatAppResponse, string, string | null]>) => {
      state.push(action.payload);
    },
    clearChatGpt: (state) => {
      state.splice(0, state.length);
    },
  },
});

export const { addChatGpt, clearChatGpt } = chatGptSlice.actions;

export default chatGptSlice.reducer;
