import FolderAccordion from "../../components/FolderAccordion/FolderAccordion";
import { Pivot, PivotItem } from "@fluentui/react";

import styles from "./FileList.module.css";
import { useMsal } from "@azure/msal-react";
import { LoginButton } from "../../components/LoginButton";
import SearchBlobList from "../../components/SearchBlobList/SearchBlobList";

const adminGroupId = import.meta.env.VITE_REACT_APP_ADMIN_SECURITY_GROUP_ID;
const commonGroupId = import.meta.env.VITE_REACT_APP_COMMON_SECURITY_GROUP_ID;

const FileList = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const tabs = [
        { name: "01-社内規定", folder: "01-社内規定/" },
        { name: "02-各種文書", folder: "02-各種文書/" },
        { name: "03-マネジメントシステム文書", folder: "03-マネジメントシステム文書/" }
    ];
    const userGroups = activeAccount?.idTokenClaims?.groups || [];
    const isAuthGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(adminGroupId) : false;
    const isCommonGroupIdInGroups = Array.isArray(userGroups) ? userGroups.includes(commonGroupId) : false;

    if (activeAccount && !isCommonGroupIdInGroups && activeAccount && !isAuthGroupIdInGroups) {
        return (
            <div className={styles.deniedContainer}>
                <h1 className={styles.title}>File List</h1>
                <div className={styles.loginMessageContainer}>
                    <p>このリソースを表示するのに十分な権限がありません。</p>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {activeAccount ? (
                <>
                    <SearchBlobList />
                    <div className={styles.fileListWrapper}>
                        <h2 className={styles.title}>File List</h2>
                        <p className={styles.titleDesc}>文書のカテゴリごとにファイルを探索できます。</p>
                        <Pivot>
                            {tabs.map(tab => (
                                <PivotItem headerText={tab.name} itemKey={tab.name} key={tab.name}>
                                    <FolderAccordion targetFolder={tab.folder} />
                                </PivotItem>
                            ))}
                        </Pivot>
                    </div>
                </>
            ) : (
                <div className={styles.loginMessageContainer}>
                    <p>文書リストを見るにはログインしてください。</p>
                    <LoginButton />
                </div>
            )}
        </div>
    );
};

export default FileList;
