import styles from "./UsedTokens.module.css";

interface Props {
    usedTokens: number;
}

function interpolateColor(progress: number) {
    const startColor = { r: 76, g: 175, b: 80 };
    const endColor = { r: 244, g: 67, b: 54 };
    const r = startColor.r + ((endColor.r - startColor.r) * progress) / 100;
    const g = startColor.g + ((endColor.g - startColor.g) * progress) / 100;
    const b = startColor.b + ((endColor.b - startColor.b) * progress) / 100;
    return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
}

export const UsedTokens = ({ usedTokens }: Props) => {
    const progressBarWidth = Math.min(200, (usedTokens / 50000) * 100);
    const progressBarColor = interpolateColor(progressBarWidth);

    return (
        <div>
            {usedTokens > 50000 ? (
                <div className={styles.limitTokenContainer}>
                    <div className={styles.tokenLimitString}>
                        1日の使用上限の5万トークンに到達しました。
                        <br />
                        トークン数は明日リセットされます。
                    </div>
                    <div className={styles.usedTokenContainer}>
                        <div className={styles.usedToken}>used tokens: 50,000 / 50,000</div>
                        <div className={styles.tokenProgressBarContainer}>
                            <div className={styles.tokenProgressBar} style={{ width: `${progressBarWidth}%`, backgroundColor: progressBarColor }}></div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.usedTokenContainer}>
                    <div className={styles.usedToken}>used tokens: {Math.min(50000, usedTokens).toLocaleString()} / 50,000</div>
                    <div className={styles.tokenProgressBarContainer}>
                        <div className={styles.tokenProgressBar} style={{ width: `${progressBarWidth}%`, backgroundColor: progressBarColor }}></div>
                    </div>
                </div>
            )}
        </div>
    );
};
