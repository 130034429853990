import { useState } from "react";
import { useFolder } from "../../api";

import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler } from "@fluentui/react-components";
import AccordionPanelContent from "../AccordionPanelContent/AccordionPanelContent";

import styles from "./FolderAccordion.module.css";

const FolderAccordion = ({ targetFolder }: { targetFolder: string }) => {
    const { data: folders, error, isLoading } = useFolder(targetFolder);
    const [openItems, setOpenItems] = useState<number[]>([]);

    const handleToggle: AccordionToggleEventHandler<number> = async (e, data) => {
        setOpenItems(data.openItems);
    };

    if (error) return <div>Error: {error}</div>;
    if (isLoading) return <div>Loading...</div>;
    if (!folders || folders.length == 0) return <div>no folders</div>;

    return (
        <div className={styles.container}>
            <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                {folders.map((folder, index) => (
                    <AccordionItem key={folder} value={index} className={styles.accordionItem}>
                        <AccordionHeader className={styles.accordionHeader}>{folder}</AccordionHeader>
                        <AccordionPanel className={styles.accordionPanel}>
                            {openItems.includes(index) && <AccordionPanelContent parentFoldername={targetFolder} folderName={folder} />}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default FolderAccordion;
