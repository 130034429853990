import { useState, useEffect } from "react";
import { Stack, TextField, IconButton, DialogFooter, DialogType, DefaultButton } from "@fluentui/react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
  } from "@fluentui/react-components";
import { Button, Tooltip, Field, Textarea } from "@fluentui/react-components";
import { Send28Filled, DocumentAdd28Filled, DismissCircle24Filled } from "@fluentui/react-icons";
import { getUsedTokens } from "../../api";
import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

import styles from "./QuestionInput.module.css";

interface Props {
    usedTokens?: number;
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    isStreaming: boolean;
    gptDeployment?: string;
    onUploadImageClick?: () => void;
    selectedImage?: File | null;
    imagePreviewUrl?: string | null;
    onRemoveImage?: () => void;
}

function interpolateColor(progress: number) {
    const startColor = { r: 76, g: 175, b: 80 };
    const endColor = { r: 244, g: 67, b: 54 };
    const r = startColor.r + ((endColor.r - startColor.r) * progress) / 100;
    const g = startColor.g + ((endColor.g - startColor.g) * progress) / 100;
    const b = startColor.b + ((endColor.b - startColor.b) * progress) / 100;
    return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
}

export const QuestionInput = ({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
    usedTokens: initialUsedTokens,
    isStreaming,
    gptDeployment,
    onUploadImageClick,
    selectedImage,
    imagePreviewUrl,
    onRemoveImage
}: Props) => {
    const client = useLogin ? useMsal().instance : undefined;
    const [question, setQuestion] = useState<string>("");
    const [usedTokens, setLocalUsedTokens] = useState<number>(initialUsedTokens || 0);
    const progressBarWidth = Math.min(100, (usedTokens / 50000) * 100);
    const progressBarColor = interpolateColor(progressBarWidth);

    const [isImageDialogOpen, setIsImageDialogOpen] = useState<boolean>(false);

    const getDailyTokenUsage = async () => {
        const token = client ? await getToken(client) : undefined;
        const response = await getUsedTokens(token?.accessToken);
        setLocalUsedTokens(response);
    };

    const sendQuestion = () => {
        setQuestion("");
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && (ev.metaKey || ev.ctrlKey)) {
            ev.preventDefault();
            sendQuestion();
            setQuestion("");
        }
    };

    const onQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";

        const newValue = event.target.value;
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 2000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    useEffect(() => {
        if (!disabled) {
            getDailyTokenUsage();
        }
    }, [disabled]);

    const userAgent = window.navigator.userAgent.toLowerCase();

    return (
        <div>
            {usedTokens <= 50000 && (
                <div className={styles.questionWrapper}>
                    <div className={styles.previewContainer}>
                        {imagePreviewUrl && (
                            <Dialog>
                                <DialogTrigger>
                                    <div className={styles.previewContainer}>
                                        <img src={imagePreviewUrl} alt="Preview" className={styles.imagePreview} />
                                        <Button
                                            icon={<DismissCircle24Filled primaryFill="rgba(0, 0, 0, 1)" />}
                                            className={styles.removeButton}
                                            onClick={e => {
                                                e.stopPropagation();
                                                onRemoveImage && onRemoveImage();
                                            }}
                                            size="small"
                                        />
                                    </div>
                                </DialogTrigger>
                                <DialogSurface style={{ backgroundColor: 'rgba(255, 255, 255, 1)', color: 'black', borderRadius: '10px' }}>
                                    <DialogBody>
                                        <DialogTitle style={{ color: 'black', fontSize: '16px', fontWeight: 'bold' }}>画像プレビュー</DialogTitle>
                                        <DialogContent>
                                            <img src={imagePreviewUrl} alt="Preview" style={{ width: "100%" }} />
                                        </DialogContent>
                                        <DialogActions>
                                            <DialogTrigger>
                                                <Button appearance="secondary">閉じる</Button>
                                            </DialogTrigger>
                                        </DialogActions>
                                    </DialogBody>
                                </DialogSurface>
                            </Dialog>
                        )}
                    </div>
                    <div className={styles.questionInputContainer}>
                        {gptDeployment === "gpt-4o" && (
                            <Button icon={<DocumentAdd28Filled primaryFill="rgba(255, 255, 255, 1)" />} onClick={onUploadImageClick} size="large" />
                        )}
                        <textarea
                            className={styles.questionInputTextArea}
                            placeholder={placeholder}
                            value={question}
                            onChange={onQuestionChange}
                            onKeyDown={isStreaming ? undefined : onEnterPress}
                            disabled={disabled}
                        />
                        <div className={styles.questionInputButtonsContainer}>
                            {isStreaming ? (
                                <Tooltip content="Wait for answer to complete" relationship="label">
                                    <Button
                                        size="large"
                                        icon={<Send28Filled primaryFill="rgba(0, 164, 239, 1)" />}
                                        disabled={sendQuestionDisabled}
                                        onClick={undefined}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip content="Ask question button" relationship="label">
                                    <Button
                                        size="large"
                                        icon={<Send28Filled primaryFill="rgba(0, 164, 239, 1)" />}
                                        disabled={sendQuestionDisabled}
                                        onClick={isStreaming ? undefined : sendQuestion}
                                    />
                                </Tooltip>
                            )}
                            <div className={styles.questionInputButtonsTexts}>
                                {userAgent.indexOf("windows") !== -1 ? <p>Ctrl + Enter</p> : null}
                                {userAgent.indexOf("mac") !== -1 ? <p>Cmd + Enter</p> : null}
                            </div>
                        </div>
                    </div>
                    <div className={styles.waringTextContainer}>
                        <p className={styles.waringText}>Kyo-kun and ChatGPT can make mistakes. Consider checking important information.</p>
                    </div>
                </div>
            )}
        </div>
    );
};
