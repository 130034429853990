import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { createNews } from "../../api";
import { mutate } from "swr";
import styles from "./NewsCreateAdmin.module.css";

const RequiredText = () => {
    return <span className={styles.required}>※ 必須</span>;
};

const NewsCreateAdmin = () => {
    const { instance } = useMsal();
    const [content, setContent] = useState("");
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    const handleCreate = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (content.trim() === "" || content.length > 200) {
            alert("内容は1文字以上200文字以内で入力してください");
            return;
        }

        const idToken = await instance.acquireTokenSilent({ scopes: ["User.Read"] });
        await createNews({ content, isDisplayed, user: "" }, idToken.accessToken);
        mutate("/news_admin");
        setContent("");
        setIsDisplayed(false);
        window.location.href = "/#/news-management";
    };

    const handleErrorText = () => {
        if (content.trim() === "" || content.length > 200) {
            setErrorText("内容は2文字以上200文字以内で入力してください");
        } else {
            setErrorText(undefined);
        }
    };

    const handleBack = (evnet: React.MouseEvent<HTMLButtonElement>) => {
        evnet.stopPropagation();
        if (window.confirm("編集中の内容が破棄されますがよろしいですか？")) {
            setContent("");
            setIsDisplayed(false);
            window.location.href = "/#/news-management";
        }
    };

    return (
        <div className={styles.newsCreateWrapper}>
            <div className={styles.contentInputWrapper}>
                <div className={styles.inputTitle}>
                    <label className={styles.titlelabel}>内容</label>
                    <RequiredText />
                </div>
                <textarea
                    value={content}
                    onChange={e => {
                        setContent(e.target.value);
                        handleErrorText();
                    }}
                    placeholder="お知らせの内容を入力して下さい。（例）キョウクンが本日より運用開始となりました。"
                    className={styles.contentInput}
                />
                <div className={styles.errorText}>{errorText}</div>
            </div>
            <div className={styles.statusWrapper}>
                <div className={styles.inputTitle}>
                    <label className={styles.titlelabel}>公開ステータス</label>
                    <RequiredText />
                </div>
                <div className={styles.radioButtonWrapper}>
                    <label className={styles.radioButton}>
                        <input type="radio" name="status" value="private" checked={!isDisplayed} onChange={() => setIsDisplayed(false)} />
                        非公開で一時保存する
                        <br />
                        <small className={styles.smallText}>※非公開の場合は、公開ステータスを変更するまでユーザーには表示されません。</small>
                    </label>
                    <label className={styles.radioButton}>
                        <input type="radio" name="status" value="public" checked={isDisplayed} onChange={() => setIsDisplayed(true)} />
                        すぐに公開する
                        <br />
                        <small className={styles.smallText}>※公開の場合は、すぐにユーザーに表示されます。</small>
                    </label>
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <button onClick={handleBack} className={styles.returnButton}>
                    戻る
                </button>
                <button onClick={handleCreate} className={isDisplayed ? styles.createButton : styles.saveButton}>
                    {isDisplayed ? "公開する" : "保存する"}
                </button>
            </div>
        </div>
    );
};

export default NewsCreateAdmin;
