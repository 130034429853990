import React from "react";
import NewsReadAdmin from "../../components/NewsTableAdmin/NewsTableAdmin";
import { Link } from "react-router-dom";
import styles from "./NewsManagement.module.css";

import sytles from "./NewsManagement.module.css";

const NewsAdmin = () => {
    return (
        <div className={sytles.NewsManagementWrapper}>
            <h1>News</h1>
            <div className={styles.createButtonContainer}>
                <Link to="/news-create" className={styles.createButton}>
                    + 新規お知らせを作成
                </Link>
            </div>
            <div>
                <NewsReadAdmin />
            </div>
        </div>
    );
};

export default NewsAdmin;
