const BACKEND_URI = "";

import {
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    NewsType,
    UpdateNewsType,
    CreateNewsType,
    BlobSearchResponse,
    BlobSuggestResponse,
    WriteFeedbackType
} from "./models";
import { useLogin } from "../authConfig";
import useSWR from "swr";
import { BlobServiceClient } from "@azure/storage-blob";

export function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    if (useLogin) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function chatGPTApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chatgpt`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

const accountName = import.meta.env.VITE_AZURE_STORAGE_ACCOUNT_NAME;
const sasToken = import.meta.env.VITE_AZURE_STORAGE_SAS_TOKEN;
const containerName = import.meta.env.VITE_AZURE_STORAGE_CONTAINER_NAME;
const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net${sasToken}`);
const containerClient = blobServiceClient.getContainerClient(containerName);

async function fetchFolder(targetFolder: string) {
    try {
        let folderNames = [];
        for await (const blob of containerClient.listBlobsFlat({ prefix: targetFolder })) {
            const relativePath = blob.name.substring(targetFolder.length);
            if (!relativePath.includes("/")) {
                folderNames.push(relativePath);
            }
        }
        return folderNames;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export function useFolder(targetFolder: string) {
    const { data, error, isLoading } = useSWR(targetFolder, () => fetchFolder(targetFolder));

    return {
        data,
        error,
        isLoading
    };
}

async function fetchFile(folderName: string) {
    try {
        let fileNames = [];
        const folderPath = folderName.endsWith("/") ? folderName : `${folderName}/`;

        for await (const blob of containerClient.listBlobsFlat({ prefix: folderPath })) {
            const relativePath = blob.name.substring(folderPath.length);
            if (relativePath && !relativePath.includes("/")) {
                const blobClient = containerClient.getBlobClient(blob.name);
                const url = blobClient.url;
                fileNames.push({ name: relativePath, url: url });
            }
        }
        return fileNames;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export function useFile(folderName: string) {
    const { data, error, isLoading } = useSWR(folderName, () => fetchFile(folderName));

    return {
        data,
        error,
        isLoading
    };
}

export function getNewsAdmin(idToken: string | undefined) {
    const fetcher = async (url: string) => {
        const response = await fetch(url, {
            method: "GET",
            headers: getHeaders(idToken)
        });

        const parsedResponse: ChatAppResponseOrError = await response.json();
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }

        return parsedResponse as NewsType[];
    };

    const { data, isLoading, error } = useSWR(`${BACKEND_URI}/news_admin`, fetcher);

    return {
        data,
        error,
        isLoading
    };
}

export async function createNews(request: CreateNewsType, idToken: string | undefined): Promise<any> {
    const response = await fetch(`${BACKEND_URI}/news`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function updateNews(request: UpdateNewsType, idToken: string | undefined): Promise<any> {
    const response = await fetch(`${BACKEND_URI}/news`, {
        method: "PUT",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function aiSearchBlobs(searchText: string): Promise<BlobSearchResponse> {
    const endpoint = import.meta.env.VITE_AZURE_AI_SEARCH_API_ENDPOINT;
    const apiKey = import.meta.env.VITE_AZURE_AI_SEARCH_API_KEY;
    const indexName = import.meta.env.VITE_AZURE_AI_SEARCH_INDEX_NAME;
    const apiVersion = import.meta.env.VITE_AZURE_AI_SEARCH_API_VERSION;
    const searchParamCount: boolean = true;
    const searchParamSearchFields: string = "content,metadata_storage_name";
    const searchParamTop: number = 10;

    const aiSearchUrl = `${endpoint}/${indexName}/docs/search?api-version=${apiVersion}`;

    const response = await fetch(aiSearchUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "api-key": apiKey
        },
        body: JSON.stringify({
            search: searchText,
            count: searchParamCount,
            searchFields: searchParamSearchFields,
            top: searchParamTop
        })
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as BlobSearchResponse;
}

export async function aiSuggestBlobs(searchText: string): Promise<BlobSuggestResponse> {
    const endpoint = import.meta.env.VITE_AZURE_AI_SEARCH_API_ENDPOINT;
    const apiKey = import.meta.env.VITE_AZURE_AI_SEARCH_API_KEY;
    const indexName = import.meta.env.VITE_AZURE_AI_SEARCH_INDEX_NAME;
    const apiVersion = import.meta.env.VITE_AZURE_AI_SEARCH_API_VERSION;
    const suggesterName = import.meta.env.VITE_AZURE_AI_SEARCH_SUGGESTER_NAME;

    const aiSuggestUrl = `${endpoint}/${indexName}/docs/suggest?api-version=${apiVersion}&search=${searchText}&suggesterName=${suggesterName}`;

    const response = await fetch(aiSuggestUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "api-key": apiKey
        }
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function writeFeedback(request: WriteFeedbackType, idToken: string | undefined): Promise<any> {
    const response = await fetch(`${BACKEND_URI}/write_feedback`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getUsedTokens(idToken: string | undefined): Promise<any> {
    const response = await fetch(`${BACKEND_URI}/getusedtoken`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
