import styles from "./AnalysisPanel.module.css";
import { IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons";

interface Props {
    activeCitation: string | undefined;
    citationHeight: string;
    onAnalysisPanelIsOpen?: () => void;
    onAnalysisPanelIsClose: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
}

export const AnalysisPanel = ({ activeCitation, citationHeight, onAnalysisPanelIsOpen, onAnalysisPanelIsClose }: Props) => {
    console.log('activeCitation', activeCitation)
    return (
        <div className={styles.container}>
            <div className={styles.citationTitleAndClosingBtn}>
                <p>Citation</p>
                <div
                    onClick={() => {
                        if (onAnalysisPanelIsOpen) {
                            onAnalysisPanelIsClose();
                        }
                    }}
                    className={styles.closingBtn}
                >
                    <IconContext.Provider value={{ color: "white", size: "1.5rem" }}>
                        <IoMdClose />
                    </IconContext.Provider>
                </div>
            </div>
            <div className={styles.citationContainer}>
                <iframe title="Citation" src={activeCitation} width="100%" height={citationHeight} />
            </div>
        </div>
    );
};
