import styles from "./Example.module.css";

interface Props {
    text: string;
    value: string;
    icon: JSX.Element;
    onClick: (value: string) => void;
    selected: boolean;
}

export const Example = ({ text, value, icon, onClick, selected }: Props) => {
    return (
        <div className={selected ? styles.exampleSelected : styles.example} onClick={() => onClick(value)}>
            {icon}
            <p className={styles.exampleText}>{text}</p>
        </div>
    );
};
