import React from "react";
import { Button, Tooltip } from "@fluentui/react-components";
import { Send28Filled } from "@fluentui/react-icons";

import styles from "./UserManual.module.css";

const UserManual = () => {
    return (
        <div className={styles.userManualWrapper}>
            <div className={styles.pageTitle}>
                <h1>User Manual</h1>
            </div>
            <div className={styles.howToUse}>
                <div className={styles.sectionTitleWrapper}>
                    <h2 className={styles.sectionTitle}>How to Use</h2>
                    <h2 className={styles.sectionTitleInJp}>使い方</h2>
                </div>
                <div className={styles.howToUseElement}>
                    <h3>1.キョウくんへの指示は具体的に、かつシンプルに書きましょう。</h3>
                    <div className={styles.questionInputContainer}>
                        <p className={styles.questionTextExample}>ooを50文字以内で、小学生にもわかるように説明して。</p>
                        <div className={styles.questionInputButtonsContainer}>
                            <Button size="large" icon={<Send28Filled primaryFill="rgba(0, 164, 239, 1)" />} />
                        </div>
                    </div>
                    <ul>
                        <li>
                            キョウくんからの出力が簡単すぎたら、<span className={styles.strongText}>続けて追加の情報</span>をたずねてみてください。
                        </li>
                        <li>
                            複雑な指示をしたい場合、最初に全てを詰め込まずに、<span className={styles.strongText}>分割して指示</span>しましょう。
                        </li>
                    </ul>
                </div>
                <div className={styles.howToUseElement}>
                    <h3>2.曖昧な言葉ではなく、数字を使って指示しましょう。</h3>

                    <div className={styles.questionInputContainer}>
                        <p className={styles.questionTextExample}>
                            ooを<span className={styles.strongText}>xx文字以内</span>にまとめて。
                        </p>
                        <div className={styles.questionInputButtonsContainer}>
                            <Button size="large" icon={<Send28Filled primaryFill="rgba(0, 164, 239, 1)" />} />
                        </div>
                    </div>
                </div>
                <div className={styles.howToUseElement}>
                    <h3>3.冒頭に指示を書き、その後に続くものに#や"を区切り文字として入れましょう。</h3>

                    <div className={styles.questionInputContainer}>
                        <p className={styles.questionTextExample}>
                            以下を<span className={styles.strongText}>箇条書きにして</span>ください。 <span className={styles.strongText}>#引用文章...</span>
                        </p>
                        <div className={styles.questionInputButtonsContainer}>
                            <Button size="large" icon={<Send28Filled primaryFill="rgba(0, 164, 239, 1)" />} />
                        </div>
                    </div>

                    <div className={styles.questionInputContainer}>
                        <p className={styles.questionTextExample}>
                            以下の<span className={styles.strongText}>要点を教えて</span>ください。
                            <span className={styles.strongText}> "引用文章..."</span>
                        </p>
                        <div className={styles.questionInputButtonsContainer}>
                            <Button size="large" icon={<Send28Filled primaryFill="rgba(0, 164, 239, 1)" />} />
                        </div>
                    </div>
                </div>
                <div className={styles.howToUseElement}>
                    <h3>4.出力の形式を指定できます。</h3>

                    <div className={styles.questionInputContainer}>
                        <p className={styles.questionTextExample}>
                            xx社について教えてください。<span className={styles.strongText}> #出力: 創業年、事業内容、本拠地</span>
                        </p>
                        <div className={styles.questionInputButtonsContainer}>
                            <Button size="large" icon={<Send28Filled primaryFill="rgba(0, 164, 239, 1)" />} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.examples}>
                <div className={styles.sectionTitleWrapper}>
                    <h2 className={styles.sectionTitle}>Examples</h2>
                    <h2 className={styles.sectionTitleInJp}>実例</h2>
                </div>
                <div className={styles.exampleElement}>
                    <div className={styles.userContainer}>
                        <div className={styles.userImgContainer}>
                            <img src="../../assets/kd-logo.png" className={styles.userImg} />
                        </div>
                        <p className={styles.userName}>ユーザー</p>
                    </div>
                    <div className={styles.answerInputContainer}>
                        <p className={styles.questionTextExample}>就業規則について300文字でまとめてください。</p>
                    </div>
                    <div className={styles.userContainer}>
                        <div className={styles.userImgContainer}>
                            <img src="../../assets/kyoden-aoai-char.png" className={styles.userImg} />
                        </div>
                        <p className={styles.userName}>キョウくん</p>
                    </div>
                    <div className={styles.answerInputContainer}>
                        <p className={styles.questionTextExample}>
                            就業規則は会社の目的を達成するために定められたものであり、労働条件や就業に関する基本的事項を規定しています。規則に従って社員は秩序を維持し、社業の発展に協力する必要があります。社員の定義や規則の適用範囲も規定されています。育児休業や育児短時間勤務に関する規則もありますが、具体的な申請手続きや制度の適用範囲については別途定められています。
                        </p>
                    </div>
                    <p>※同じプロンプトでも同じ内容が出力されないことがあります</p>
                </div>
                <div className={styles.exampleElement}>
                    <div className={styles.userContainer}>
                        <div className={styles.userImgContainer}>
                            <img src="../../assets/kd-logo.png" className={styles.userImg} />
                        </div>
                        <p className={styles.userName}>ユーザー</p>
                    </div>
                    <div className={styles.answerInputContainer}>
                        <p className={styles.questionTextExample}>マイカー規定について200文字以内でまとめてください。</p>
                    </div>
                    <div className={styles.userContainer}>
                        <div className={styles.userImgContainer}>
                            <img src="../../assets/kyoden-aoai-char.png" className={styles.userImg} />
                        </div>
                        <p className={styles.userName}>キョウくん</p>
                    </div>
                    <div className={styles.answerInputContainer}>
                        <p className={styles.questionTextExample}>
                            マイカー規定の要点は次のとおりです：
                            <br />
                            - マイカー通勤は、社員が所有または占有する自動車や原動機付自転車を使用して通勤することを指します。
                            <br />
                            - マイカー通勤を希望する場合は、申請書を提出し、会社から承認を得る必要があります。
                            <br />
                            - マイカー通勤者は道路交通法を遵守し、安全運転を行う義務があります。また、自動車保険に加入することも求められます。
                            <br />
                            - マイカー通勤者には通勤手当が支給されます。支給額は通勤距離に基づいて計算されます。
                            <br />- マイカー通勤者は会社指定の駐車場に駐車する必要があります。駐車場代は別表に定められています。
                        </p>
                    </div>
                    <p>※同じプロンプトでも同じ内容が出力されないことがあります</p>
                </div>
            </div>
        </div>
    );
};

export default UserManual;
