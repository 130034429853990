import React, { useState } from "react";

import { IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { DirectionalHint } from "@fluentui/react/lib/Callout";
import { useConst, useBoolean, useId } from "@fluentui/react-hooks";

import { Info20Regular } from "@fluentui/react-icons";

import styles from "./GptDeploymentSwitcher.module.css";
import { useMsal } from "@azure/msal-react";

const GptDeploymentSwitcher = ({
    gptDeployment,
    setGptDeployment,
    gptDeploymentItems,
    disabled
}: {
    gptDeployment: string;
    setGptDeployment: (gptDeployment: string) => void;
    gptDeploymentItems: { id: string; gptDeploymentName: string }[];
    disabled: boolean;
}) => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const buttonId = useId("targetButton");
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

    const menuProps = useConst<IContextualMenuProps>(() => ({
        shouldFocusOnMount: true,
        items: gptDeploymentItems.map(item => ({
            key: item.id,
            text: item.id,
            onClick: () => setGptDeployment(item.gptDeploymentName)
        }))
    }));

    return (
        <div className={styles.gptDeploymentContainer}>
            {activeAccount && (
                <>
                    <div className={styles.gptDeploymentTitle}>
                        <p>GPTモデルを選択</p>
                    </div>
                    <div className={styles.buttonContainer}>
                        <DefaultButton
                            text={gptDeploymentItems.find(item => item.gptDeploymentName === gptDeployment)?.id}
                            menuProps={menuProps}
                            className={styles.gptDeploymentButton}
                            disabled={disabled}
                        />
                        <Info20Regular id={buttonId} onClick={toggleTeachingBubbleVisible} className={styles.infoButton} />
                        {teachingBubbleVisible && (
                            <TeachingBubble
                                calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                                target={`#${buttonId}`}
                                isWide={true}
                                hasCloseButton={true}
                                closeButtonAriaLabel="Close"
                                onDismiss={toggleTeachingBubbleVisible}
                                headline="会話で使用するGPTのモデルを選択できるようになりました。"
                            >
                                GPT-4oは、より高い精度で回答ができます。また、GPT-4oは画像を用いた問い合わせに対応しています。
                            </TeachingBubble>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default GptDeploymentSwitcher;
