export type ExampleListType = {
    [key: string]: { text: string; value: string; children?: { text: string; value: string }[]; category?: string }[];
};

export type ListType = {
    text: string;
    value: string;
    children?: { text: string; value: string }[];
    category?: string;
};

// 社内規定
const rules: ListType[] = [
    {
        text: "1. 生産・販売規定について知りたい",
        value: "",
        children: [
            // {
            //     text: "a. 見積基準について",
            //     value: "生産販売についての規定のうち、見積基準について教えてください。"
            // },
            {
                text: "a. 在庫について",
                value: "在庫について教えてください。"
            },
            {
                text: "b. 不良品について",
                value: "不良品について教えてください。"
            },
            {
                text: "c. 販売管理規定について",
                value: "販売管理規定について教えてください。"
            },
            {
                text: "d. 品質管理規定について",
                value: "品質管理規定について教えてください。"
            },
            {
                text: "e. 生産管理規定について",
                value: "生産管理規定について教えてください。"
            },
        ],
        category: "production"
    },
    {
        text: "2. 組織規定について知りたい",
        value: "",
        children: [
            {
                text: "a. 職務分掌規定について",
                value: "職務分掌規定について教えてください。"
            },
            {
                text: "b. 稟議決済規定について",
                value: "稟議決済規定について教えてください。"
            },
            {
                text: "c. 人事規定について",
                value: "人事規定について教えてください。"
            },
            {
                text: "d. 内部監査規定について",
                value: "内部監査規定について教えてください。"
            },
        ],
        category: "accounting"
    },
    {
        text: "3. 人事規定について知りたい",
        value: "",
        children: [
            {
                text: "a. 人事管理規定について",
                value: "人事規定について教えてください。"
            },
            {
                text: "b. 出張規定について",
                value: "出張規定について教えてください。"
            },
            {
                text: "c. 給与規定について",
                value: "給与について教えてください。"
            },
            {
                text: "d. 就業規則について",
                value: "就業規則について教えてください"
            },
        ],
        category: "accounting"
    },
    {
        text: "4. 経理規定について聞きたい",
        value: "",
        children: [
            {
                text: "a. 経理規定について",
                value: "経理規定について教えてください。"
            },
            {
                text: "b. 原価計算の規定について",
                value: "経理についての規定のうち、原価計算管理規定について教えてください。"
            },
        ],
        category: "accounting"
    },
];

// 人事規定

export const exampleList: ExampleListType = {
    rules,
};
